import axios from 'axios';

export default {
    methods: {
        async checkBuildHash() {
            try {
                const { data } = await axios.get('/public/build-hash.json');
                const currentHash = data.buildHash;
                const cachedHash = localStorage.getItem('buildHash');

                if (cachedHash && cachedHash !== currentHash) {
                    // Hashes are different, reload the page
                    localStorage.setItem('buildHash', currentHash);
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000); // Wait for 1 second before reloading
                } else if (!cachedHash) {
                    // No cached hash, set it for the first time
                    localStorage.setItem('buildHash', currentHash);
                }
            } catch (error) {
                console.error('Failed to check build hash:', error);
            }
        }
    },
    mounted() {
        this.checkBuildHash();
    },
    watch: {
        '$route.path': 'checkBuildHash'
    }
};