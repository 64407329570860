<template>
  <div id="app">
    <v-app>
        <router-view/>
    </v-app>
  </div>
</template>

<script>
  import checkBuildHash from './mixins/checkBuildHash';

  export default {
    name: 'App',
    mixins: [checkBuildHash],

    watch: {
        '$route.path': 'checkAndReload'
    },

    mounted() {
        this.checkAndReload();
    },

    methods: {
        async checkAndReload() {
            if (process.env.NODE_ENV === 'production'){
                try {
                    const response = await fetch('/public/build-hash.json');
                    const data = await response.json();
                    const currentHash = data.buildHash;
                    const savedHash = localStorage.getItem('buildHash');

                    if (savedHash && savedHash !== currentHash) {
                        localStorage.setItem('buildHash', currentHash);
                        window.location.reload();
                    } else if (!savedHash) {
                        localStorage.setItem('buildHash', currentHash);
                    }
                } catch (error) {
                    console.error('Error fetching build hash:', error);
                }
            }
        }
    },

    metaInfo: {
        titleTemplate: '%s - Harmony Fan',
        title: 'Inicio',
        meta: [
            { charset: 'utf-8' },
            { name: 'viewport', content: 'width=device-width, initial-scale=1' },
            { name: 'description', content: 'Harmony fan es una herramienta que te permite con un solo Clic conectar tu audiencia con tus plataformas y ganar dinero al hacerlo.' },
            // Agrega metaetiquetas para compartir en redes sociales
            { property: 'og:title', content: 'Harmony Fan' },
            { property: 'og:description', content: 'Harmony fan es una herramienta que te permite con un solo Clic conectar tu audiencia con tus plataformas y ganar dinero al hacerlo.' },
            { property: 'og:image', content: 'https://backend.harmony.fan/storage/images/harmony.png' },  // Reemplaza con la ruta de tu favicon
            { property: 'og:url', content: 'https://harmony.fan/' },  // Reemplaza con la URL de tu sitio
            { name: 'twitter:card', content: 'summary_large_image' },  // Tipo de tarjeta de Twitter
        ],
        link: [
          {
            rel: 'icon',
            type: 'image/x-icon',
            href: './favicon.ico'
          }
        ]
    }
  };
</script>

<style lang="scss">

    @import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

    #app {
        //font-family: Avenir, Helvetica, Arial, sans-serif;
        font-family: Rubik, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        //color: #2c3e50;
    }

    .swal2-title {
        font-family: Rubik, sans-serif;
    }

    .swal2-html-container {
        font-family: Rubik, sans-serif;
    }

    .swal2-styled{
        font-family: Rubik, sans-serif;
    }

    .swal2-confirm {
        font-weight: 400 !important;
    }

    .swal2-cancel {
        font-weight: 400 !important;
    }

    ::-moz-selection { /* Code for Firefox */
        color: white;
        background: #8e84c0;
    }

    ::selection {
        color: white;
        background: #8e84c0;
    }

    .title-main-hero span {
        display: block;
        font-size: 53px;
        font-weight: 800;
    }

    .title-second-hero span {
        display: block;
        font-size: 48px;
        font-weight: 800;
    }

    ::-webkit-scrollbar {
        width: 5px;
    }

    ::-webkit-scrollbar-track {
        background-color: #e7e7e7;
        -webkit-border-radius: 10px;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
        -webkit-border-radius: 10px;
        border-radius: 10px;
        background: #bdbdbd;
    }

    .input-capitalize input {
        text-transform: capitalize;
    }

</style>
