<template>
    <div>
        <NavBar/>
        <v-container class="text-center my-16">
            <v-row>
                <v-col>
                    <img src="../assets/illustrations/404-error.svg" class="logo">
                    <h1>{{ $t('404.title') }}</h1>
                    <p>{{ $t('404.desc') }}</p>
                    <v-btn color="#8e84c0" class="white--text mb-16" @click="$router.push('/')">{{ $t('404.button') }}</v-btn>
                </v-col>
            </v-row>
        </v-container>
        <Footer/>
    </div>
</template>

<script>
    import NavBar from '../components/partials/NavBar.vue';
    import Footer from '../components/partials/Footer.vue';

    export default {
        name: 'NotFound',
        components: {
            NavBar,
            Footer
        },
        metaInfo: {
            titleTemplate: '%s - Harmony Fan',
            title: '404 - Página no encontrada'
        }
    }
</script>

<style scoped>
    h1 {
        font-size: 3rem;
        margin-top: 2rem;
    }

    p {
        font-size: 1.5rem;
        margin-top: 1rem;
    }

    .logo {
        width: 22rem;
    }

    @media (max-width: 959px) {
        .logo {
            width: 17rem;
        }
    }
</style>
