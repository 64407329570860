<template>
    <div>
        <v-navigation-drawer v-model="drawer" absolute temporary app>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>
                        <v-btn text href="/" class="text-black">
                            <img src="../../assets/logo/harmony-logo.png" class="logo-mobile title">
                        </v-btn>
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list dense>
                <v-list-item link>
                    <v-btn text href="/" class="text-black link">
                        {{ $t('navbar.home') }}
                    </v-btn>
                </v-list-item>

                <v-list-item link>
                    <v-btn text class="text-black link" @click="scrollToPlans">
                        {{ $t('navbar.plans') }}
                    </v-btn>
                </v-list-item>

                <v-list-item link>
                    <v-btn text class="text-black link" to="/contact">
                        {{ $t('navbar.contact') }}
                    </v-btn>
                </v-list-item>



                <v-list-item link class="mt-2" v-if="user">
                    <v-btn outlined color="black" class="text-black link ma-2 rounded-md py-4" small
                        @click="goTo('/panel')">
                        <v-icon left>
                            mdi-apps
                        </v-icon>
                        Panel
                    </v-btn>
                </v-list-item>

                <v-list-item link class="mt-2" v-if="user">
                    <v-btn color="red lighten-2" class="white--text link ma-2 rounded-md py-4" small @click="logout()">
                        <v-icon center color="white" size="20px" class="mr-1">
                            mdi-power-plug-off-outline
                        </v-icon>
                        Desconectar
                    </v-btn>
                </v-list-item>

                <v-list-item link class="mt-2" v-if="!user">
                    <v-btn color="black" class="white--text link ma-2 rounded-xl py-4" small @click="loginModal()">
                        <v-icon left>
                            mdi-login-variant
                        </v-icon>
                        {{ $t('navbar.login') }}
                    </v-btn>
                </v-list-item>

                <v-list-item link class="mt-1" v-if="!user">
                    <v-btn text class="text-black link" @click="registerModal()">
                        <v-icon left>
                            mdi-plus-circle-outline
                        </v-icon>
                        {{ $t('navbar.register') }}
                    </v-btn>
                </v-list-item>

                <v-list-item link class="mt-3">

                    <v-select v-model="locale" :items="locales" @change="changeLocale" :label="$t('navbar.language')"
                        class="ml-0 mr-3 col-12" outlined dense item-value="value" item-text="text" return-object>
                        <template v-slot:selection="{ item }">
                            <v-avatar size="24">
                                <img :src="item.flag" />
                            </v-avatar>
                            <span class="ml-2">{{ item.text }}</span>
                        </template>
                        <template v-slot:item="{ item }">
                            <v-avatar size="24">
                                <img :src="item.flag" />
                            </v-avatar>
                            <span class="ml-2">{{ item.text }}</span>
                        </template>
                    </v-select>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar color="white">
            <v-container>
                <v-row align="center" justify="space-between">
                    <div>
                        <v-btn text href="/" class="text-black">
                            <img src="../../assets/logo/harmony-logo.png" class="logo">
                        </v-btn>
                        <!--<v-btn text href="/" class="text-black link hidden-sm-and-down">
                            {{ $t('navbar.home') }}
                        </v-btn>-->
                        <v-btn text class="text-black link hidden-sm-and-down" @click="scrollToPlans">
                            {{ $t('navbar.plans') }}
                        </v-btn>
                        <v-btn text class="text-black link hidden-sm-and-down" to="/contact">
                            {{ $t('navbar.contact') }}
                        </v-btn>
                    </div>
                    <v-row align="center" justify="end">

                        <template v-if="user">
                            <v-menu open-on-hover :close-on-content-click="false" :nudge-width="200" offset-x>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" class="mx-2 btn-profile" fab dark outlined
                                        color="white">
                                        <transition name="fadeInDown">
                                            <v-badge bordered bottom color="teal accent-3" dot offset-x="10"
                                                offset-y="10" v-if="user.logo_path">
                                                <v-avatar size="40">
                                                    <v-img :src="user.logo_path"></v-img>
                                                </v-avatar>
                                            </v-badge>

                                            <v-badge bordered bottom color="teal accent-3" dot offset-x="10" offset-y="10"
                                                class="mr-3" v-else>
                                                <v-avatar color="black" size="38">
                                                    <span class="white--text text-p">
                                                        {{ user.first_name.charAt(0)+user.last_name.charAt(0) }}
                                                    </span>
                                                </v-avatar>
                                            </v-badge>
                                        </transition>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-list max-width="350">
                                        <v-list-item>
                                            <v-list-item-content class="text-left">
                                                <v-list-item-title>{{user.first_name + " " +
                                                    user.last_name}}</v-list-item-title>
                                                <v-list-item-subtitle class="mt-4">{{user.email}}</v-list-item-subtitle>
                                                <v-list-item-subtitle class="mt-2">{{user.phone_code + " "+
                                                    user.phone_number}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            <v-list-item-avatar height="70" width="70">
                                                <v-img cover
                                                    :src="user.logo_path ? user.logo_path : require('../../assets/illustrations/no-photo.jpg')" />
                                            </v-list-item-avatar>
                                        </v-list-item>
                                    </v-list>

                                    <v-divider></v-divider>

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="#8e84c0"
                                            to="/panel/profile"
                                            class="white--text"
                                        >
                                        {{ $t('navbar.editProfile') }}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-menu>
                        </template>

                        <template v-if="user">
                            <transition name="fadeInDown">
                                <v-btn outlined color="black"
                                    class="text-black link ma-2 rounded-md hidden-sm-and-down py-4" small
                                    @click="goTo('/panel')">
                                    <v-icon left>
                                        mdi-apps
                                    </v-icon>
                                    Panel
                                </v-btn>
                            </transition>

                            <transition name="fadeInDown">
                                <v-btn color="red lighten-2" class="link ma-2 rounded-md hidden-sm-and-down py-4" small
                                    @click="logout()">
                                    <v-icon center color="white" size="20px">
                                        mdi-power-plug-off-outline
                                    </v-icon>
                                </v-btn>
                            </transition>
                        </template>

                        <v-btn color="black" class="white--text link ma-2 rounded-xl hidden-sm-and-down py-4" small
                            @click="loginModal()" v-if="!user">
                            <v-icon left>
                                mdi-login-variant
                            </v-icon>
                            {{ $t('navbar.login') }}
                        </v-btn>

                        <v-btn text class="text-black link hidden-sm-and-down" @click="registerModal()" v-if="!user">
                            <v-icon left>
                                mdi-plus-circle-outline
                            </v-icon>
                            {{ $t('navbar.register') }}
                        </v-btn>

                        <v-col style="max-width: 200px;" class="p-0 m-0 hidden-sm-and-down">
                            <v-select v-model="locale" :items="locales" @change="changeLocale"
                                :label="$t('navbar.language')" class="ml-4 mr-3 mt-6 hidden-sm-and-down" outlined dense
                                item-value="value" item-text="text" return-object>
                                <template v-slot:selection="{ item }">
                                    <v-avatar size="24">
                                        <img :src="item.flag" />
                                    </v-avatar>
                                    <span class="ml-2">{{ item.text }}</span>
                                </template>
                                <template v-slot:item="{ item }">
                                    <v-avatar size="24">
                                        <img :src="item.flag" />
                                    </v-avatar>
                                    <span class="ml-2">{{ item.text }}</span>
                                </template>
                            </v-select>
                        </v-col>

                        <v-btn text icon @click.stop="drawer = !drawer" color="black" class="hidden-md-and-up">
                            <v-icon>mdi-menu</v-icon>
                        </v-btn>
                    </v-row>
                </v-row>
            </v-container>
        </v-app-bar>

        <v-dialog transition="dialog-top-transition" max-width="600" v-model="login">
            <v-card>
                <v-card-text class="py-0 px-0">
                    <v-form ref="formLogin" lazy-validation>
                        <v-alert border="right" colored-border color="#8e84c0" elevation="0"
                            class="col-12 px-10 py-10 mb-0">
                            <div class="text-left">

                                <v-row class="mb-0">
                                    <v-icon class="ml-2 mt-n1" size="26px" color="#454547">
                                        mdi-shield-key
                                    </v-icon>
                                    <h2 class="mb-2 mt-1 ml-2 mt-0 title-modal">{{ $t('navbar.access') }}</h2>
                                </v-row>
                                <p class="mb-10 desc">{{ $t('navbar.access-desc') }}</p>

                                <v-text-field v-model="email" id="email" :label="$t('navbar.email')" type="email"
                                    outlined clearable color="#8e84c0" class="mb-0"
                                    :rules="[rules.required, rules.email]"></v-text-field>

                                <div class="password-field-container">
                                    <v-text-field v-model="password" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                        :rules="[rules.required, rules.min]" :type="show1 ? 'text' : 'password'"
                                        :label="$t('navbar.password')" color="#8e84c0" outlined clearable
                                        @click:append="show1 = !show1" />
                                    <div class="forgot-password-link float-right px-1">
                                        <v-btn text small @click="passwordReset = true"
                                            class="d-flex align-center pa-0 ma-0">
                                            <v-icon left color="#8e84c0">mdi-lock-question</v-icon>
                                            {{$t('navbar.forgot')}}
                                        </v-btn>
                                    </div>
                                </div>


                            </div>
                            <div class="text-right mt-2">
                                <v-btn :disabled="!isFormValid || loginLoading" @click="loginUser"
                                    :loading="loginLoading" color="#8e84c0"
                                    class="ml-0 white--text link ma-2 rounded-md px-16 col-12 col-md-5">
                                    <v-icon left color="white">
                                        mdi-cloud-key-outline
                                    </v-icon>
                                    {{ $t('navbar.access-button') }}
                                </v-btn>
                            </div>
                        </v-alert>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog transition="dialog-top-transition" max-width="600" v-model="register">
            <v-card>
                <v-card-text class="py-0 px-0">
                    <v-form ref="formRegister" lazy-validation>
                        <v-alert border="right" colored-border color="#8e84c0" elevation="0"
                            class="col-12 px-10 py-10 mb-0">
                            <div class="text-left">
                                <v-row class="mb-0">
                                    <v-icon class="ml-2 mt-n1" size="26px" color="#454547">
                                        mdi-shield-account
                                    </v-icon>
                                    <h2 class="mb-2 mt-1 ml-2 mt-0 title-modal">{{ $t('navbar.register') }}</h2>
                                </v-row>
                                <p class="mb-10 desc">{{ $t('navbar.register-desc') }}</p>

                                <v-row>
                                    <v-col cols="12" md="6" class="pa-0">
                                        <v-text-field id="first_name" v-model="first_name" :label="$t('navbar.name')"
                                            type="text" outlined clearable color="#8e84c0"
                                            class="mb-0 input-capitalize col-12 col-sm-6 px-3"
                                            :rules="[rules.required, rules.alpha, rules.minName, rules.maxName]"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" class="pa-0">
                                        <v-text-field v-model="last_name" id="last_name" :label="$t('navbar.last-name')"
                                            type="text" outlined clearable color="#8e84c0"
                                            class="mb-3 mb-md-2 input-capitalize col-12 col-sm-6 px-3"
                                            :rules="[rules.required, rules.alpha, rules.minName, rules.maxName]"></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-text-field v-model="email" id="email" name="email" :label="$t('navbar.email')"
                                    type="email" outlined clearable color="#8e84c0" class="mb-0"
                                    :rules="[rules.required, rules.email]"></v-text-field>

                                <v-row>
                                    <div class="col-12 col-md-4 pb-0 m-0">
                                        <v-autocomplete v-model="phone_code" :items="phone_codes" item-text="dial_code"
                                            item-value="dial_code" color="#8e84c0" outlined
                                            :label="$t('navbar.dial-code')" class="pr-0 pr-md-1"
                                            :rules="[rules.required]">
                                            <template v-slot:selection="{ item }">
                                                <v-avatar size="24">
                                                    <img :src="item.flag" />
                                                </v-avatar>
                                                <span class="ml-2">{{ item.dial_code }}</span>
                                            </template>
                                            <template v-slot:item="{ item }">
                                                <v-avatar size="24">
                                                    <img :src="item.flag" />
                                                </v-avatar>
                                                <span class="ml-2">{{ item.dial_code }}</span>
                                            </template>
                                        </v-autocomplete>
                                    </div>

                                    <div class="col-12 col-md-8 py-0 pb-2 py-md-3">
                                        <v-text-field id="phone_number" v-model="phone_number"
                                            :label="$t('navbar.phone')" type="text" outlined clearable color="#8e84c0"
                                            class="mb-0 pl-0 pl-md-1"
                                            :rules="[rules.required, rules.numericPhone, rules.minPhone, rules.maxPhone]">
                                            <template v-slot:append>
                                                <v-tooltip top>
                                                  <template v-slot:activator="{ on, attrs }">
                                                    <v-icon
                                                      v-bind="attrs"
                                                      v-on="on"
                                                    >
                                                      mdi-information-outline
                                                    </v-icon>
                                                  </template>
                                                  <span>{{$t('navbar.phone-tooltip')}}</span>
                                                </v-tooltip>
                                              </template>
                                        </v-text-field>
                                    </div>
                                </v-row>

                                <v-autocomplete
                                    outlined
                                    :label="$t('navbar.userType')"
                                    :items="userType"
                                    item-value="id"
                                    item-text="name"
                                    v-model="role_id"
                                    color="#8e84c0"
                                    :rules="[rules.required]"
                                    :disabled="registerLoading"
                                >
                                    <!-- Renderizado de los ítems en la lista -->
                                    <template v-slot:item="{ item, on, attrs }">
                                        <v-list-item v-bind="attrs" v-on="on">
                                            <v-list-item-content>
                                                <v-list-item-title class="text-left"><span v-html="item.name"></span></v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-autocomplete>

                                <v-text-field v-model="password" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                    :rules="[rules.required, rules.min]" :type="show1 ? 'text' : 'password'"
                                    :label="$t('navbar.password')" counter color="#8e84c0" outlined clearable
                                    @click:append="show1 = !show1"></v-text-field>

                                <v-text-field v-model="password_confirm"
                                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                    :rules="[rules.required, rules.min, rules.passwordMatch]"
                                    :type="show1 ? 'text' : 'password'" :label="$t('navbar.confirm-password')" counter
                                    color="#8e84c0" outlined clearable @click:append="show1 = !show1"></v-text-field>
                            </div>
                            <div class="text-right">
                                <v-btn :disabled="!isFormValid2 || registerLoading" @click="registerUser"
                                    :loading="registerLoading" color="#8e84c0"
                                    class="ml-0 white--text link ma-2 rounded-md px-16 col-12 col-md-5">
                                    <v-icon left color="white">
                                        mdi-account-key-outline
                                    </v-icon>
                                    {{ $t('navbar.register-button') }}
                                </v-btn>
                            </div>
                        </v-alert>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog transition="dialog-top-transition" max-width="600" v-model="passwordReset">
            <v-card>
                <v-card-text class="py-0 px-0">
                    <v-form ref="formReset" lazy-validation>
                        <v-alert border="right" colored-border color="#8e84c0" elevation="0"
                            class="col-12 px-10 py-10 mb-0">
                            <div class="text-left">

                                <v-row class="mb-0">
                                    <v-icon class="ml-2 mt-n1" size="26px" color="#454547">
                                        mdi-lock-question
                                    </v-icon>
                                    <h2 class="mb-2 mt-1 ml-2 mt-0 title-modal">{{ $t('navbar.reset-password') }}</h2>
                                </v-row>
                                <p class="mb-10 desc">{{ $t('navbar.reset-password-desc') }}</p>

                                <v-text-field id="email" name="email" v-model="email_reset" :label="$t('navbar.email')"
                                    type="email" autocomplete="on" outlined clearable color="#8e84c0" class="mb-0"
                                    :rules="[rules.required, rules.email]"></v-text-field>

                            </div>
                            <div class="text-right ">
                                <v-btn :disabled="!isFormValid3 || forgotPasswordLoading" @click="sendLinkResetPassword"
                                    :loading="forgotPasswordLoading" color="#8e84c0"
                                    class="ml-0 white--text link ma-2 rounded-md px-16 col-12 col-md-5">
                                    <v-icon left color="white">
                                        mdi-email-arrow-right-outline
                                    </v-icon>
                                    {{ $t('navbar.reset-button-send') }}
                                </v-btn>
                            </div>
                        </v-alert>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog transition="dialog-top-transition" max-width="600" persistent v-model="passwordResetSend">
            <v-card>
                <v-card-text class="py-0 px-0">
                    <v-form ref="formReset" lazy-validation>
                        <v-alert border="right" colored-border color="#8e84c0" elevation="0"
                            class="col-12 px-10 py-10 mb-0">
                            <div class="text-left">

                                <v-row class="mb-0">
                                    <v-icon class="ml-2 mt-n1" size="26px" color="#454547">
                                        mdi-shield-key-outline
                                    </v-icon>
                                    <h2 class="mb-2 mt-1 ml-2 mt-0 title-modal">{{ $t('navbar.reset-password') }}</h2>
                                </v-row>
                                <p class="mb-10 desc">{{ $t('navbar.reset-password-change') }}</p>

                                <v-text-field v-model="newPassword" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                    :rules="[rules.required, rules.min]" :type="show1 ? 'text' : 'password'"
                                    :label="$t('navbar.password')" counter color="#8e84c0" outlined clearable
                                    @click:append="show1 = !show1"></v-text-field>

                                <v-text-field v-model="newPasswordConfirm"
                                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                    :rules="[rules.required, rules.min, rules.passwordMatch2]"
                                    :type="show1 ? 'text' : 'password'" :label="$t('navbar.confirm-password')" counter
                                    color="#8e84c0" outlined clearable @click:append="show1 = !show1"></v-text-field>

                            </div>
                            <div class="text-right ">
                                <v-btn :disabled="!isFormValid4 || sendNewPasswordLoading" @click="sendNewPassword"
                                    :loading="sendNewPasswordLoading" color="#8e84c0"
                                    class="button-reset-password ml-0 white--text link ma-2 rounded-md  col-12 col-md-5">
                                    <v-icon left color="white">
                                        mdi-shield-key-outline
                                    </v-icon>
                                    {{ $t('navbar.reset-password-button') }}
                                </v-btn>
                            </div>
                        </v-alert>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import axios from '@/axios.js';


    export default {
        watch: {
            "$i18n.locale": function () {
                this.setUserType();
            },
        },
        name: 'NavBar',
        data () {
            return {
                drawer: null,
                locale: null,
                locales: [
                    { value: 'en', text: 'English', flag: require('@/assets/flags/usa.png') },
                    { value: 'es', text: 'Español', flag: require('@/assets/flags/spain.png') }
                ],
                show1: false,
                first_name: '',
                last_name: '',
                email: '',
                email_reset: '',
                passwordResetSend: false,
                password: '',
                password_confirm: '',
                phone_code: '(+57)',
                phone_number: '',
                login: false,
                loginLoading: false,
                register: false,
                registerLoading: false,
                user: null,
                phone_codes: null,
                passwordReset: false,
                newPassword: '',
                newPasswordConfirm: '',
                forgotPasswordLoading: false,
                emailForReset: '',
                sendNewPasswordLoading: false,
                hideInput: true,
                rules: {
                    required: value => !!value || this.$t('navbar.required'),
                    min: v => v.length >= 8 || this.$t('navbar.min'),
                    minPhone: v => v.length >= 6 || this.$t('navbar.min-phone'),
                    maxPhone: v => v.length <= 12 || this.$t('navbar.max-phone'),
                    numericPhone: v => /^[0-9]+$/.test(v) || this.$t('navbar.numeric-phone'),
                    passwordMatch: value => value === this.password || this.$t('navbar.password-match'),
                    passwordMatch2: value => value === this.newPassword || this.$t('navbar.password-match'),
                    alpha: v => /^[a-zA-ZÑñ ]*$/.test(v) || this.$t('navbar.alpha'),
                    minName: v => v.length >= 3 || this.$t('navbar.min-name'),
                    maxName: v => v.length <= 30 || this.$t('navbar.max-name'),
                    email: value => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        return pattern.test(value) || this.$t('navbar.invalid-email')
                    }
                },
                userType: [],
                loadingUserType: false,
                role_id: 3,
            }
        },

        mounted() {
            this.$root.$on('user-updated', this.updateUser);
            window.addEventListener('storage', this.syncUserWithLocalStorage);
            this.checkToken()
            const urlParams = new URLSearchParams(window.location.search);
            const referral = urlParams.get('referral');
            console.log(referral)
            if (referral) {
                localStorage.setItem('referral', referral);
            }
        },

        computed: {
            isFormValid() {
                return (
                    this.rules.required(this.email) === true &&
                    this.rules.email(this.email) === true &&
                    this.rules.required(this.password) === true &&
                    this.rules.min(this.password) === true
                );
            },

            isFormValid2() {
                return (
                    this.rules.required(this.first_name) === true &&
                    this.rules.alpha(this.first_name) === true &&
                    this.rules.minName(this.first_name) === true &&
                    this.rules.maxName(this.first_name) === true &&
                    this.rules.required(this.last_name) === true &&
                    this.rules.alpha(this.last_name) === true &&
                    this.rules.minName(this.last_name) === true &&
                    this.rules.maxName(this.last_name) === true &&
                    this.rules.required(this.email) === true &&
                    this.rules.email(this.email) === true &&
                    this.rules.required(this.phone_code) === true &&
                    this.rules.required(this.phone_number) === true &&
                    this.rules.numericPhone(this.phone_number) === true &&
                    this.rules.minPhone(this.phone_number) === true &&
                    this.rules.maxPhone(this.phone_number) === true &&
                    this.rules.required(this.password) === true &&
                    this.rules.min(this.password) === true &&
                    this.rules.required(this.password_confirm) === true &&
                    this.rules.min(this.password_confirm) === true &&
                    this.rules.passwordMatch(this.password_confirm) === true
                );
            },

            isFormValid3() {
                return (
                    this.rules.required(this.email_reset) === true &&
                    this.rules.email(this.email_reset) === true
                );
            },

            isFormValid4() {
                return (
                    this.rules.required(this.newPassword) === true &&
                    this.rules.min(this.newPassword) === true &&
                    this.rules.required(this.newPasswordConfirm) === true &&
                    this.rules.min(this.newPasswordConfirm) === true &&
                    this.rules.passwordMatch2(this.newPasswordConfirm) === true
                );
            },
        },

        created() {
            this.setLanguage()
            this.checkUser()
            this.$root.$on('openRegisterModal', this.registerModal);
            this.$root.$on('openPanel', this.handlePanel);
            this.setUserType();
        },

        beforeDestroy() {
            this.$root.$off('openRegisterModal', this.registerModal);
            this.$root.$off('openPanel', this.handlePanel);
            this.$root.$off('user-updated', this.updateUser);
            window.removeEventListener('storage', this.syncUserWithLocalStorage);
        },

        methods: {

            clickInput(){
                this.hideInput = !this.hideInput
                console.log(this.hideInput)
            },

            getHideInput(){
                if (this.hideInput){
                    return 'hide-input'
                }
            },

            updateUser(updatedUser) {
                this.user = updatedUser;
            },
            syncUserWithLocalStorage() {
                const user = localStorage.getItem("user");
                if (user) {
                    this.user = JSON.parse(user);
                }
            },

            goTo(url){
                if (this.user.role_id == 2 && url == '/panel'){
                    this.$router.push('/panel');
                }else if (this.user.role_id == 1 && url == '/panel'){
                    //this.$router.push('/panel/admin');
                    window.location.href = this.$router.resolve({ path: '/panel/admin' }).href;
                }else{
                    this.$router.push(url);
                }
            },

            handlePanel() {
                this.goTo('/panel');
            },

            checkUser(){
                let user = localStorage.getItem("user");
                if (user){
                    this.user = JSON.parse(user);
                }
            },

            logout() {
                try {
                        axios.get('/logout');

                        this.$vs.notify({
                            title: this.$t('navbar.disconnection'),
                            text: this.$t('navbar.disconnection-desc'),
                            color: 'success',
                            icon: 'check_box',
                            position: 'bottom-center',
                            time: 5000,
                        });
                        localStorage.removeItem("user");
                        localStorage.removeItem("token");
                        this.$router.push('/');
                } catch (error) {
                    console.error(error);
                }
            },

            async loginUser() {
                try {
                    this.loginLoading = true
                    const response = await axios.post('/login', {
                        email: this.email,
                        password: this.password
                    });
                        localStorage.removeItem("impersonate_admin");
                        localStorage.setItem("user", JSON.stringify(response.data.user));
                        localStorage.setItem("token", response.data.user.token);
                        this.user = response.data.user;

                        this.$root.$emit('userUpdated', this.user);

                        this.$vs.notify({
                            title: this.$t('navbar.login-title'),
                            text: this.$t('navbar.login-success'),
                            color: 'success',
                            icon: 'check_box',
                            position: 'bottom-center',
                            time: 5000,
                        });
                        this.email = ''
                        this.password = ''
                        this.$refs.formLogin.resetValidation()
                        this.loginLoading = false
                        this.login = false;

                        if (this.user.role_id != 1){
                            this.$router.push('/panel');
                        }else{
                            //this.$router.push('/panel/admin');
                            window.location.href = this.$router.resolve({ path: '/panel/admin' }).href;
                        }

                } catch (error) {
                    console.error(error);

                    localStorage.removeItem("user");
                    localStorage.removeItem("token");

                    this.loginLoading = false
                    this.$vs.notify({
                        title: this.$t('navbar.login-title-failed'),
                        text: this.$t('navbar.login-invalid'),
                        color: 'danger',
                        icon: 'warning',
                        position: 'bottom-center',
                        time: 5000,
                    });
                }
            },

            async registerUser() {
                const referralCode = localStorage.getItem('referral');
                try {
                    this.registerLoading = true
                    const response = await axios.post('/register', {
                        first_name: this.first_name,
                        last_name: this.last_name,
                        email: this.email,
                        phone_code: this.phone_code,
                        phone_number: this.phone_number,
                        password: this.password,
                        password_confirmation: this.password_confirm,
                        role_id: this.role_id,
                        referral: referralCode
                    });
                    console.log(response)
                        localStorage.setItem("user", JSON.stringify(response.data));
                        localStorage.setItem("token", response.data.token);
                        this.user = response.data;
                        this.$root.$emit('userUpdated', this.user);

                        this.$vs.notify({
                            title: this.$t('navbar.register-complete'),
                            text: this.$t('navbar.register-com-desc'),
                            color: 'success',
                            icon: 'check_box',
                            position: 'bottom-center',
                            time: 5000,
                        });

                        this.clearRegisterForm()

                        this.registerLoading = false
                        this.register = false;

                        this.$router.push('/panel');
                } catch (error) {
                    console.error(error);

                    localStorage.removeItem("user");
                    localStorage.removeItem("token");

                    if (error.response.data.errors){
                        if (error.response.data.errors.email){
                            let errorData = error.response.data.errors.email
                            errorData.forEach(element => {
                                if (element == 'The email has already been taken.'){
                                    this.$vs.notify({
                                        title: this.$t('navbar.login-title-failed'),
                                        text: this.$t('navbar.email-taken'),
                                        color: 'danger',
                                        icon: 'warning',
                                        position: 'bottom-center',
                                        time: 5000,
                                    });
                                }
                            });
                        }else if (error.response.data.errors.phone_number){
                            let errorData = error.response.data.errors.phone_number
                            errorData.forEach(element => {
                                if (element == 'The phone number has already been taken.'){
                                    this.$vs.notify({
                                        title: this.$t('navbar.login-title-failed'),
                                        text: this.$t('navbar.phone-taken'),
                                        color: 'danger',
                                        icon: 'warning',
                                        position: 'bottom-center',
                                        time: 5000,
                                    });
                                }
                            });
                        }else{
                            this.$vs.notify({
                                title: this.$t('navbar.login-title-failed'),
                                text: this.$t('navbar.register-failed'),
                                color: 'danger',
                                icon: 'warning',
                                position: 'bottom-center',
                                time: 5000,
                            });
                        }
                    }

                    this.registerLoading = false
                }
            },

            async sendLinkResetPassword() {
                this.forgotPasswordLoading = true;
                try {
                     await axios.post('/send-link-password', {
                        email: this.email_reset,
                        lang: this.$i18n.locale
                    });

                    this.$vs.notify({
                        title: this.$t('navbar.reset-success-title'),
                        text: this.$t('navbar.reset-password-success'),
                        color: 'success',
                        icon: 'check_box',
                        position: 'bottom-center',
                        time: 5000,
                    });

                    setTimeout(() => {
                        this.passwordReset = false;
                    }, 1000);
                } catch (error) {
                    console.error(error);
                    this.$vs.notify({
                        title: this.$t('navbar.reset-failed-title'),
                        text: this.$t('navbar.reset-password-invalid'),
                        color: 'danger',
                        icon: 'warning',
                        position: 'bottom-center',
                        time: 5000,
                    });
                }
                this.forgotPasswordLoading = false;

            },

            async checkToken() {
                try {
                    const token = this.$route.query.token;
                    const email = this.$route.query.email;
                    const user = localStorage.getItem("user");
                    if (token && email) {
                        const response = await axios.post('verify-token-reset-password', {
                            token: token,
                            email: email
                        });
                        if (response.data.status === 'success' &&  user === null) {
                            this.emailForReset = email;
                            this.passwordResetSend = true;
                        } else if (user != null) {
                            this.$router.push('/panel');
                        }else{
                            this.$vs.notify({
                                title: this.$t('navbar.token-expired'),
                                text: this.$t('navbar.token-expired-desc'),
                                color: 'danger',
                                icon: 'warning',
                                position: 'bottom-center',
                                time: 5000,
                            });
                        }
                    }
                } catch (error) {
                    console.error(error);
                    this.$vs.notify({
                        title: this.$t('navbar.token-expired'),
                        text: this.$t('navbar.token-expired-desc'),
                        color: 'danger',
                        icon: 'warning',
                        position: 'bottom-center',
                        time: 5000,
                    });
                }
            },

            async sendNewPassword(){
                try {
                    this.sendNewPasswordLoading = true;
                    const response = await axios.post('/reset-password', {
                        email: this.emailForReset,
                        password: this.newPassword,
                        password_confirmation: this.newPasswordConfirm,
                        token: this.$route.query.token
                    });

                    if(response.data.status === 'success'){

                        this.$vs.notify({
                            title: this.$t('navbar.password-success-title'),
                            text: this.$t('navbar.reset-success-desc'),
                            color: 'success',
                            icon: 'check_box',
                            position: 'bottom-center',
                            time: 5000,
                        });

                        setTimeout(() => {
                            this.passwordResetSend = false;
                            this.$router.push('/');
                            this.login = true;
                        }, 1000);
                    }

                    this.sendNewPasswordLoading = false;

                } catch (error) {
                    console.error(error);
                }
            },

            async getPhoneCodes() {
                try {
                    const response = await axios.get('/get-phone-codes');
                    
                    this.phone_codes = response.data.map(code => {
                        code.flag = `https://flagcdn.com/24x18/${code.code.toLowerCase()}.png`;
                        return code;
                    });
                } catch (error) {
                    console.error(error);
                }
            },

            clearRegisterForm (){
                this.first_name = ''
                this.last_name = ''
                this.email = ''
                this.code = null
                this.phone_code = ''
                this.password = ''
                this.confirm_password = ''
                this.$refs.formRegister.resetValidation()
            },

            loginModal(){
                this.email = ''
                this.password = ''

                this.login = !this.login
                this.drawer = false
            },

            registerModal(){
                this.getPhoneCodes()
                this.email = ''
                this.password = ''
                this.password_confirm = ''

                this.register = !this.register
                this.drawer = false
            },

            changeLocale() {
                this.$i18n.locale = this.locale.value;
                localStorage.setItem('locale', this.locale.value);
                this.$root.$emit('languageChanged', this.locale.value);
                this.$vs.notify({
                    title: this.$t('navbar.language'),
                    text: this.$t('navbar.language-success'),
                    color: 'success',
                    icon: 'check_box',
                    position: 'bottom-center',
                    time: 5000,
                })
            },

            setLanguage(){
                const storedLocale = localStorage.getItem('locale');
                if (storedLocale) {
                    this.locale = (storedLocale == 'es') 
                        ? { value: 'es', text: 'Español', flag: require('@/assets/flags/spain.png') } 
                        : { value: 'en', text: 'English', flag: require('@/assets/flags/usa.png') };
                    this.$i18n.locale = storedLocale;
                } else {
                    const browserLocale = navigator.language.slice(0, 2);
                    if (browserLocale === 'en' || browserLocale === 'es') {
                        this.locale = (browserLocale == 'es') 
                            ? { value: 'es', text: 'Español', flag: require('@/assets/flags/spain.png') } 
                            : { value: 'en', text: 'English', flag: require('@/assets/flags/usa.png') };
                        this.$i18n.locale = browserLocale;
                        localStorage.setItem('locale', browserLocale);
                    } else {
                        this.locale = { value: 'es', text: 'Español', flag: require('@/assets/flags/spain.png') };
                        this.$i18n.locale = 'es';
                        localStorage.setItem('locale', 'es');
                    }
                }
            },

            scrollToPlans() {
                // Verificar si ya estamos en la ruta 'home'
                if (this.$route.name !== 'Home') {
                    this.$router.push({ name: 'Home' }).then(() => {
                        this.scrollToPlansSection();
                    });
                } else {
                    this.scrollToPlansSection();
                }
            },

            scrollToPlansSection() {
                const plansSection = document.getElementById('plans-section');
                if (plansSection) {
                    plansSection.scrollIntoView({ behavior: 'smooth' });
                }
                this.drawer = false;

                this.$vs.notify({
                    title: this.$t('navbar.plans'),
                    text: this.$t('navbar.plans-interested'),
                    color: '#d4b4ef',
                    icon: 'info',
                    position: 'top-center',
                    time: 5000,
                });
            },

            setUserType() {
                this.userType = [
                    { id: 3, name: this.$t('navbar.userCreator') },
                    { id: 2, name: this.$t('navbar.userViewer') },
                ];
            }
        }
    };
</script>

<style>

    .hide-input .v-input__control input {
        display: none;
    }

    .userTypes {
        font-weight: 500;
    }

    .title-modal {
        color: #454547;
    }

    .password-field-container {
        position: relative;
    }
    .forgot-password-link {
    margin-top: -28px;

    }

    .desc {
        color: #9390a1;
    }

    .logo {
        width: 169px;
    }

    .logo-mobile {
        margin-left: -24px;
        width: 172px;
    }

    .title {
        font-size: 25px !important;
        text-transform: none !important; /* Evita la transformación a mayúsculas */
    }

    .link {
        text-transform: none !important;
    }

    .container {
        margin-top: 18px;
        max-width: 1100px !important;
    }

    header {
        height: 86px !important;
    }

    .border {
        border-color: gray;
        border-style: solid;
        border-width: 1px;
    }

    .gradient {
        width: 185px;
        padding: 8px;
        border-radius: 9px;
        background: #8e84c0;
        background: -moz-linear-gradient(90deg, #8e84c0 0%, rgb(236, 196, 255) 100%);
        background: -webkit-linear-gradient(90deg, #8e84c0 0%, rgb(236, 196, 255) 100%);
        background: linear-gradient(90deg, #8e84c0 0%, rgb(236, 196, 255) 100%);
        filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#8e84c0", endColorstr="rgb(236, 196, 255)", GradientType=1);
    }

    @media (max-width: 959px) {
        .logo {
            margin-left: -8px;
        }
    }
</style>
