// src/i18n.js
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from './lang/en.json';
import es from './lang/es.json';

Vue.use(VueI18n);

const messages = {
  en,
  es
};

const i18n = new VueI18n({
  locale: 'en', // idioma por defecto
  fallbackLocale: 'en',
  messages,
});

export default i18n;
