<template>
  <v-footer color="black">
    <v-container>
      <v-row justify="center" align="center">
        <v-col cols="12" md="4" class="text-center text-md-left">
          <v-btn text href="/" class="text-black">
              <img src="../../assets/logo/harmony.png" class="logo-footer">
          </v-btn>
          <div class="text-white">&copy; {{ $t('footer.copyright') }}</div>
        </v-col>
        <v-col cols="12" md="4" class="text-center text-md-center">
          <ul class="footer-links">
            <li>
                <router-link to="/terms" class="text-white">{{ $t('footer.terms') }}</router-link>
            </li>
            <li>
                <router-link to="/cookies" class="text-white">{{ $t('footer.cookies') }}</router-link>
            </li>
          </ul>
        </v-col>
        <v-col cols="12" md="4" class="text-center text-md-right">
          <ul class="footer-links">
            <li>
                <router-link to="/about" class="text-white">{{ $t('footer.whais') }}</router-link>
            </li>
            <li>
              <router-link to="/report" class="text-white">{{ $t('footer.report') }}</router-link>
            </li>
          </ul>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
    export default {
        name: 'Footer'
    };
</script>

<style scoped>

    .logo-footer {
		margin-left: -17px;
		width: 174px;
	}

    .text-white {
        color: white !important;
    }

    .footer-links {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .footer-links li {
        margin-bottom: 8px; /* Ajusta el espaciado entre los enlaces según sea necesario */
    }

    .footer-links a {
        color: white;
        text-decoration: none;
    }

    .footer-links a:hover {
        text-decoration: underline;
    }
</style>
